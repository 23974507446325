<template>
  <div class="job-position-layout">
    <header class="job-position-layout__header">
      <h1 class="header-title">
        <img
          src="/img/top/logo.svg"
          width="207"
          height="19"
          alt="WARC エージェント"
          class="header-title__img"
        />
      </h1>
      <ClientOnly>
        <p class="header-text" v-if="!isPc">管理部門・士業の転職エージェント</p>
      </ClientOnly>
    </header>
    <slot />
    <footer class="jobPosition-footer">
      <div class="jobPosition-footer__contents">
        <div class="contents-box">
          <span class="contents-box__title">運営会社</span>
          <span class="contents-box__text">株式会社WARC</span>
        </div>
        <div class="contents-box">
          <span class="contents-box__title">所在地</span>
          <span class="contents-box__text">
            〒141-0021
            <br />
            東京都品川区上大崎2-25-2 新目黒東急ビル9F
          </span>
        </div>
        <div class="contents-box">
          <span class="contents-box__title">資本金</span>
          <span class="contents-box__text">
            800,000,000円（資本準備金含む）
          </span>
        </div>
        <div class="contents-box">
          <span class="contents-box__title">代表取締役</span>
          <span class="contents-box__text">山本 彰彦</span>
        </div>
        <div class="contents-box">
          <span class="contents-box__title">設立年月</span>
          <span class="contents-box__text">2017年5月</span>
        </div>
        <div class="contents-box">
          <span class="contents-box__title">従業員数</span>
          <span class="contents-box__text">90人</span>
        </div>
        <div class="contents-box">
          <span class="contents-box__title">有料職業紹介許可番号</span>
          <span class="contents-box__text">13-ユ-308828</span>
        </div>
      </div>
      <span class="jobPosition-footer__copy">&copy; 2024 WARC.inc</span>
    </footer>
  </div>
</template>

<script setup lang="ts">
const isPc = useState(() => false);

const checkWindowWidth = () => {
  isPc.value = screen.width > 767;
};

onMounted(() => {
  checkWindowWidth();
  window.addEventListener('resize', checkWindowWidth);
});
</script>
